import React, { useState, useEffect, useCallback, useMemo } from 'react';
import './App.css';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter, faDiscord } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import tile00 from './tile00.png';
import tile01 from './tile01.png';
import tile03 from './tile02.png';
import tutorialLevel from './tutorialLevel.json'; 
import music2 from './music2.mp3';

const App = () => {
  const [score, setScore] = useState(0);
  const [timeLeft, setTimeLeft] = useState(60);
  const [level, setLevel] = useState(1);
  const [moves, setMoves] = useState(0);
  const [combo, setCombo] = useState(1);
  const [selectedTile, setSelectedTile] = useState(null);
  const [tiles, setTiles] = useState([]);
  const [lockedTiles, setLockedTiles] = useState([]);
  const [isMuted, setIsMuted] = useState(false);
  const backgroundMusic = useMemo(() => new Audio(music2), []);
  const [highScore, setHighScore] = useState(localStorage.getItem('highScore') || 0);
  const [showHighScoreModal, setShowHighScoreModal] = useState(false);
  const [lastMatchTime, setLastMatchTime] = useState(Date.now());
  const [gameOver, setGameOver] = useState(false);
  const [gameStarted, setGameStarted] = useState(false);
  const [showTutorial, setShowTutorial] = useState(true);
  const [tutorialComplete, setTutorialComplete] = useState(false);
  const [flipTiles, setFlipTiles] = useState(false);
  const loadTutorialLevel = () => {
  const data = tutorialLevel; 
  setTiles(data.tiles.map(index => tileImages[index]));
  setLockedTiles(data.lockedTiles);
};

  const tileImages = useMemo(() => [tile00, tile01, tile03], []);

  const endGame = useCallback(() => {
    setGameOver(true);
    setGameStarted(false);
    if (score > highScore) {
      setHighScore(score);
      localStorage.setItem('highScore', score);
    }
  }, [score, highScore]);

  // Timer effect
useEffect(() => {
  let gameInterval;
  if (gameStarted && tutorialComplete && timeLeft > 0) {
    gameInterval = setInterval(() => {
      setTimeLeft((prevTime) => prevTime - 1);
    }, 1000);
  } else if (timeLeft === 0) {
    endGame();
  }
  return () => clearInterval(gameInterval);
}, [timeLeft, gameStarted, tutorialComplete, endGame]);

  const toggleMute = () => {
  setIsMuted((prevIsMuted) => {
    const newMuteState = !prevIsMuted;
    backgroundMusic.muted = newMuteState;

    if (!newMuteState && gameStarted) {
      backgroundMusic.play();
    }

    return newMuteState;
  });
};

  // Shuffle array helper
  const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
  };

const createSafeGrid = useCallback(() => {
  let newTiles;
  let newLockedTiles;
  let lockCount = 0;

  // Determine how many tiles to lock based on the level
  if (level <= 5) {
    lockCount = 0;
  } else if (level > 5 && level <= 10) {
    lockCount = 1;
  } else {
    lockCount = 2;
  }

  do {
    newTiles = [...tileImages, ...tileImages, ...tileImages]; 
    shuffleArray(newTiles);
    newLockedTiles = new Array(9).fill(false);

    let currentLockCount = 0;

    // Define potential lock positions
    const potentialLockPositions = [...Array(9).keys()];

    while (currentLockCount < lockCount) {
      // Randomly select a position from valid positions
      const index = Math.floor(Math.random() * potentialLockPositions.length);
      const position = potentialLockPositions[index];

      // Only allow placement if the position is not already locked
      if (!newLockedTiles[position]) {
        const isAdjacentToOtherLocks = [
          position >= 3 && newLockedTiles[position - 3], // Above
          position < 6 && newLockedTiles[position + 3], // Below
          position % 3 > 0 && newLockedTiles[position - 1], // Left
          position % 3 < 2 && newLockedTiles[position + 1] // Right
        ].some(Boolean);

        // Allow lock placement if it's the first lock or it is adjacent to another lock
        if (currentLockCount === 0 || isAdjacentToOtherLocks) {
          newLockedTiles[position] = true;
          currentLockCount++;
        }
      }
    }
  } while (!isSolvable(newTiles, newLockedTiles));

  setTiles(newTiles);
  setLockedTiles(newLockedTiles);
}, [tileImages, level]);

const isSolvable = (tilesArray, lockedArray) => {
  const rows = [[0, 1, 2], [3, 4, 5], [6, 7, 8]];
  const columns = [[0, 3, 6], [1, 4, 7], [2, 5, 8]];

  // Prevent locking entire rows or columns
  for (let row of rows) {
    if (row.every(i => lockedArray[i])) return false;
  }
  for (let column of columns) {
    if (column.every(i => lockedArray[i])) return false;
  }

  // Ensure no matching locked tiles are placed
  for (let i = 0; i < 9; i++) {
    if (lockedArray[i]) {
      for (let j = i + 1; j < 9; j++) {
        if (lockedArray[j] && tilesArray[i] === tilesArray[j]) return false;
      }
    }
  }

  return true;
};

const selectTileHandler = (index) => {
    if (lockedTiles[index] || flipTiles) return; 

    if (showTutorial) {
        setShowTutorial(false);
        setTutorialComplete(true);
    }

    if (selectedTile === index) {
        setSelectedTile(null);
    } else if (selectedTile === null) {
        setSelectedTile(index);
    } else {
        if (isAdjacent(selectedTile, index)) {
            swapTiles(selectedTile, index);
            setSelectedTile(null);
            setMoves(prevMoves => prevMoves + 1);
            updateMoves();
        } else {
            setSelectedTile(index);
        }
    }
};

const isAdjacent=(index1 ,index2 )=>{
const row1=Math.floor(index1 /3 );
const col1=index1 %3 ;
const row2=Math.floor(index2 /3 );
const col2=index2 %3 ;
return(Math.abs(row1 -row2 )===1 &&col1 ===col2 )||
(Math.abs(col1 -col2 )===1 &&row1 ===row2 );
};

const swapTiles=(index1 ,index2 )=>{
let newTiles=[...tiles ];
[newTiles[index1 ],newTiles[index2 ]]=[newTiles[index2 ],newTiles[index1 ]];
setTiles(newTiles );
};

const updateMoves=()=>{
if(moves >=15 ){
endGame();
}
};

const endLevel = useCallback(() => {
    if (flipTiles) return;

    const baseTimeBonus = 10;
    const minTimeBonus = 3;
    const timeBonusDecreaseRate = 2;

    // Only decrease the bonus every 3rd level
    const levelsDecrement = Math.floor(level / 3);

    const timeBonus = Math.max(minTimeBonus, baseTimeBonus - levelsDecrement * timeBonusDecreaseRate);

    if (tutorialComplete) {
        setScore(prevScore => prevScore + timeLeft * combo);
        setTimeLeft(prevTimeLeft => prevTimeLeft + timeBonus);
        setLevel(prevLevel => prevLevel + 1);
    } else {
        setLevel(prevLevel => prevLevel + 1);
    }

    setMoves(0);
    
    setFlipTiles(true);
    setTimeout(() => {
        setFlipTiles(false);
        createSafeGrid();
    }, 500);
}, [level, timeLeft, combo, tutorialComplete, createSafeGrid, flipTiles]);

const checkWinCondition = useCallback(() => {
    if (flipTiles) return;

    const rows = [[0, 1, 2], [3, 4, 5], [6, 7, 8]];
    const columns = [[0, 3, 6], [1, 4, 7], [2, 5, 8]];
    let horizontalWin = rows.every(row => row.every(i => tiles[i] === tiles[row[0]]));
    let verticalWin = columns.every(column => column.every(i => tiles[i] === tiles[column[0]]));

    // Check if win condition met
    if (horizontalWin || verticalWin) {
        const now = Date.now();
        if (now - lastMatchTime < 5000) {
            setCombo(combo + 1);
        } else {
            setCombo(1);
        }
        setLastMatchTime(now);
        endLevel();
    }
}, [tiles, lastMatchTime, combo, endLevel, flipTiles]);

// UseEffect to check for win after every tile swap
useEffect(()=>{
checkWinCondition();
},[tiles,checkWinCondition]);

const startGame = () => {
  setScore(0);
  setTimeLeft(50);
  setLevel(1);
  setMoves(0);
  setCombo(1);
  setGameOver(false);
  setGameStarted(true);
  setShowTutorial(true);
  setTutorialComplete(false);
  loadTutorialLevel();
  
  // Start playing the background music
  if (!isMuted) {
    backgroundMusic.play();
    backgroundMusic.loop = true;
  }
};

const returnToMenu = () => {
  backgroundMusic.pause();
  backgroundMusic.currentTime = 0;
  setGameStarted(false);
  setGameOver(false);
};

const [showAboutModal, setShowAboutModal] = useState(false);
const [showHowToPlayModal, setShowHowToPlayModal] = useState(false);

return (
  <div className="game-container">
        {/* Mute Button */}
    <div className="mute-container">
            <button onClick={toggleMute} className="mute-button">
                {isMuted ? <VolumeOffIcon /> : <VolumeUpIcon />}
            </button>
        </div>
    {/* Title Section */}
    <div className="title-container">
      <div className="left-side">
        <h1>HUEZZLE</h1>
        {/* Placeholder text shown before game starts */}
        {!gameStarted && (
          <p className="placeholder-text">A techDuckie Productions</p>
        )}
      </div>
      
      {/* Tile images next to the title */}
      <div className="tile-images">
        <img src={tile01} alt="Tile 1" className="small-tile" />
        <img src={tile03} alt="Tile 2" className="small-tile" />
        <img src={tile00} alt="Tile 3" className="small-tile" />
      </div>
    </div>

    {/* Game Started Section */}
    {gameStarted && (
      <>
        {/* Display Game Stats */}
        <div className="game-stats-container">
          <div className="game-stat">Score: {score}</div>
          <div className="game-stat time-stat">Time: {timeLeft}s</div>
          <div className="game-stat">Level: {level}</div>
          <div className="game-stat moves-stat">Moves: {moves}/15</div>
          <div className="game-stat combo-stat">Combo: x{combo}</div>
        </div>

        {/* Game Board Rendering */}
        {gameStarted && showTutorial && (
          <div className="tutorial-arrow"></div>  
        )}
        <div id="game-board">
          {tiles.map((tileImage, index) => (
            <div className="tile-wrapper" key={index}>
              <div
                className={`tile${lockedTiles[index] ? ' locked' : ''}${selectedTile === index ? ' selected' : ''}${flipTiles ? ' flip' : ''}${showTutorial && index === 0 ? ' shake1' : ''}${showTutorial && index === 1 ? ' shake2' : ''}`}
                style={{
                  backgroundImage: `url(${tileImage})`,
                  backgroundSize: 'cover',
                }}
                onClick={() => selectTileHandler(index)}
              ></div>
            </div>
          ))}
        </div>
      </>
    )}

    {/* Pre-Game Menu Section */}
    {!gameStarted && (
      <>
        <div className="button-container">
          {/* Play/Play Again Button */}
          <button 
            onClick={startGame} 
            className={gameOver ? 'button play-again' : 'button play'}
          >
            {gameOver ? 'Play Again' : 'Play'}
          </button>

          {/* Highscore, About, and How to Play Buttons */}
          {!gameOver && (
            <button onClick={() => setShowHighScoreModal(true)} className="button">
              Highscore
            </button>
          )}
          {!gameOver && (
            <button onClick={() => setShowAboutModal(true)} className="button">
              About
            </button>
          )}
          <button onClick={() => setShowHowToPlayModal(true)} className="button">
            How to Play
          </button>

          {/* Menu Button on Game Over */}
          {gameOver && (
            <button onClick={returnToMenu} className="button">
              Menu
            </button>
          )}
        </div>
      </>
    )}

    {/* Game Over Section */}
    {gameOver && (
      <div className="game-over">
        <h2>Game Over!</h2>
        <p>Your score: {score}</p>
        <p>Levels completed: {level - 1}</p>
      </div>
    )}

    {/* Menu Button during Gameplay */}
    {gameStarted && (
      <button onClick={returnToMenu} className="button">Menu</button>
    )}

    {/* High Score Modal */}
    {showHighScoreModal && (
      <div className="high-score-modal">
        <h2>Highscore</h2>
        <p>{highScore}</p>
        <button onClick={() => setShowHighScoreModal(false)} className="button">
          Close
        </button>
      </div>
    )}

      {/* About Modal */}
      {showAboutModal && (
        <div className="high-score-modal">
          <h2>About Me</h2>
          <p>
            I'm Badankan (devTechDuckie), a self-taught developer with a passion for coding and the creator of Huezzle, made entirely on a phone for the React Jam Fall 2024. Shoutout to GustavoVituri for his awesome design that helped bring this game to life!
          </p>
      
          <div className="contact-icons">
            <div className="icon-row">
              <FontAwesomeIcon icon={faTwitter} size="2x" />
              <span>@TechDuckie</span>
            </div>
      
            <div className="icon-row">
              <FontAwesomeIcon icon={faDiscord} size="2x" />
              <span>Badankan</span>
            </div>
      
            <div className="icon-row">
              <FontAwesomeIcon icon={faEnvelope} size="2x" />
              <span>DevTechDuckie@gmail.com</span>
            </div>
          </div>
      
          <button onClick={() => setShowAboutModal(false)} className="button">
            Close
          </button>
        </div>
      )}

    {/* How to Play Modal */}
    {showHowToPlayModal && (
      <div className="high-score-modal">
        <h2>How to Play</h2>
        <p>Match 3 lines vertically or horizontally by swapping one tile with an adjacent tile. Locked tiles can’t be moved. You have 60 seconds total and regain some time for completing a level. Complete as many levels as possible before time runs out, and finish quickly for a combo bonus!</p>
        <button onClick={() => setShowHowToPlayModal(false)} className="button">
          Close
        </button>
      </div>
    )}
  </div>
);

};

export default App;